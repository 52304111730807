*{
    font-family: "Montserrat", sans-serif;
}

.impressum {
    padding-top: 10px;
    color: black;
    text-decoration: none;
}

.impressum-main-heading {
    margin-top: 0;
    margin-bottom: 50px;
    text-align: center;
    color:#529fcc;
}

.impressum-container {
    text-align: left;
    padding: 10px 40px 10px 40px;
    line-height: 1.5em;
}