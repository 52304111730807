* {
  padding: 0;
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif
}

#root,
body {
  width: 100% !important;
}

.toggle-location-container{
  display: flex;
}

.dark body {
  background-image: repeating-linear-gradient(45deg, rgba(97, 97, 97, 0.1) 0px, rgba(97, 97, 97, 0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(43, 43, 43), rgb(43, 43, 43));

}

.weather-forecast-options {
  display: flex;
  flex-direction: row;
}

.weather-forecast-option {
  font-size: 18px;
  padding: 4px 14px 4px 10px;
  margin-top: 14px;
  text-decoration: none;

}

.weather-forecast-option-text {
  color: whitesmoke;

}

.dark .weather-forecast-option-text {
  color: steelblue;

}

.weather-forecast-option-text:hover {
  color: rgb(255, 255, 255);
  transition: all 0.15s ease-in;

}

.dark .weather-forecast-option-text:hover {
  color: rgb(94, 161, 216);
  transition: all 0.15s ease-in;

}

#basic-navbar-nav.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.footer-app-name {
  color: #000;
}

button.location.toggle_btn {
  border: none !important;
}

.text-dark.bg-white.dark-modal-body.modal-body {
  background-color: #ffd43b !important;
}

.got-it-button.text-dark.bg-white.dark-modal-button.btn.btn-primary {
  background-color: whitesmoke !important;
  color: #000 !important;
}

.got-it-button.text-dark.bg-white.dark-modal-button.btn.btn-primary:hover {
  background-color: #529fcc !important;
  color: white !important;
}

.dark.city-name-pre,
.dark.postal-code-pre {
  color: #337cb4 !important;

}

/* .impressum-section.dark{
  background-color: #272626;
} */

.city-name-pre,
.postal-code-pre {
  color: #337cb4 !important;

}



.got-it-button {
  /* background-color: #eee7e7 !important; */
}

.footer-app-name.dark {
  color: #c5bc15;
}

.navigation.container {
  background-color: #337cb4;
}

.navigation.container.dark {
  background-color: #272626;

}

.measurement-systems {
  background-color: #337cb4;
}

.measurement-systems.dark {
  background-color: #272626;

}

.day-24-heading,
.before-midday,
.week-7-heading {
  background-color: none;
  /* color:white; */
}

.before-midday,
.week-7-heading {
  padding-bottom: 46px !important;
  padding-top: 46px !important;

}

.dark.day-24-heading,
.dark.before-midday,
.dark.week-7-heading {
  background-color: rgba(113, 163, 188, 0.7) !important;
  color: white;
}

.dark.before-midday,
.dark.week-7-heading {
  padding-bottom: 46px !important;
  padding-top: 46px !important;

}


.app-heading {
  background-color: #337cb4;
}

.app-heading.dark {
  background-color: #272626;

}

.clear-input-button {
  background-color: #337cb4;
}

.clear-input-button.dark {
  background-color: #272626;

}

.footer {
  background-color: #337cb4;
}

.footer.dark {
  background-color: #272626;

}

.main-weather-card .moving-background-light {
  background-image: url("./img/sunny-day.jpg") !important;
  background-size: 200% 100% !important;
  width: 100% !important;


}

.main-weather-card .moving-background-dark {
  background-image: url("./img/starry-night.jpg") !important;
  background-size: 150% 100% !important;
  width: 100% !important;


}

.weather-form {
  background-color: #337cb4;
}

.weather-form.dark {
  background-color: #272626;

}


nav.page-header {
  background-color: #337cb4;

}

nav.page-header.dark {
  background-color: #272626;

}

.form-heading-container {
  background-color: #337cb4;

}

.form-heading-container.dark {
  background-color: #272626;

}

.toggle_btn {
  background-color: transparent;
  margin-left: 25px;
  border: none;

}



.powered-by-link {
  color: #c5bc15;
}

.wind-north {
  animation: windNorth 2.6s infinite;
}

@keyframes windNorth {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }

  100% {
    transform: translateY(0);
  }
}

.wind-south {
  animation: windSouth 2.6s infinite;
}

@keyframes windSouth {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0);
  }
}

.wind-east {
  animation: windEast 2.6s infinite;
}

@keyframes windEast {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0);
  }
}

.wind-ene {
  animation: windENE 2.6s infinite;
}

@keyframes windENE {
  0% {
    transform: translateX(0) translateY(0) rotate(18deg);
  }

  50% {
    transform: translateX(8px) translateY(-8px) rotate(18deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(18deg);
  }
}

.wind-west {
  animation: windWest 2.6s infinite;
}

@keyframes windWest {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-8px);
  }

  100% {
    transform: translateX(0);
  }
}

.wind-wsw {
  animation: windWSW 2.6s infinite;
}

@keyframes windWSW {
  0% {
    transform: translateX(0) translateY(0) rotate(24deg);
  }

  50% {
    transform: translateX(-8px) translateY(8px) rotate(24deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(24deg);
  }
}

.wind-sw {
  animation: windSW 2.6s infinite;
}

@keyframes windSW {
  0% {
    transform: translateX(0) translateY(0);

  }

  50% {
    transform: translateX(-8px) translateY(8px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}


.wind-ssw {
  animation: windSSW 2.6s infinite;
}

@keyframes windSSW {
  0% {
    transform: translateX(0) translateY(0) rotate(-12deg);

  }

  50% {
    transform: translateX(-8px) translateY(8px) rotate(-12deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(-12deg);
  }
}

.wind-se {
  animation: windSE 2.6s infinite;
}

@keyframes windSE {
  0% {
    transform: translateX(0) translateY(0);

  }

  50% {
    transform: translateX(8px) translateY(8px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

.wind-nw {
  animation: windNW 2.6s infinite;
}

@keyframes windNW {
  0% {
    transform: translateX(0) translateY(0);

  }

  50% {
    transform: translateX(8px) translateY(8px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

.wind-ne {
  animation: windNE 2.6s infinite;
}

@keyframes windNE {
  0% {
    transform: translateX(0) translateY(0);

  }

  50% {
    transform: translateX(-8px) translateY(8px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

.wind-nne {
  animation: windNNE 2.6s infinite;
}

@keyframes windNNE {
  0% {
    transform: translateX(0) rotate(-14deg) translateY(0);

  }

  50% {
    transform: translateX(-8px) rotate(-14deg) translateY(8px);
  }

  100% {
    transform: translateX(0) rotate(-14deg) translateY(0);
  }
}

.toggle_btn.dark {
  background-color: #272626;
  margin-left: 25px;
  border: none;

}


/* loader */

.preloader {
  position: absolute;
  margin-left: -55px;
  margin-top: -100px;
  height: 110px;
  width: 110px;
  left: 50%;
  top: 80%;
}

.preloader svg {
  width: 110px;
  height: 110px;
}

.preloader path {
  stroke: #9ea1a4;
  stroke-width: 0.25;
  fill: #241E20;
}

#cloud {
  position: relative;
  z-index: 2;
}

#cloud path {
  fill: #efefef;
}

#sun {
  margin-left: -10px;
  margin-top: 6px;
  opacity: 0;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 45px;
  top: 15px;
  z-index: 1;

  animation-name: rotate;
  animation-duration: 16000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#sun path {
  stroke-width: 0.18;
  fill: #9ea1a4;
}


@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

/* Rain */
.rain {
  position: absolute;
  width: 70px;
  height: 70px;
  margin-top: -32px;
  margin-left: 19px;
}

.drop {
  opacity: 1;
  background: #9ea1a4;
  display: block;
  float: left;
  width: 3px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0px 0px 6px 6px;

  animation-name: drop;
  animation-duration: 850ms;
  animation-iteration-count: infinite;
}

.drop:nth-child(1) {
  animation-delay: -130ms;
}

.drop:nth-child(2) {
  animation-delay: -240ms;
}

.drop:nth-child(3) {
  animation-delay: -390ms;
}

.drop:nth-child(4) {
  animation-delay: -525ms;
}

.drop:nth-child(5) {
  animation-delay: -640ms;
}

.drop:nth-child(6) {
  animation-delay: -790ms;
}

.drop:nth-child(7) {
  animation-delay: -900ms;
}

.drop:nth-child(8) {
  animation-delay: -1050ms;
}

.drop:nth-child(9) {
  animation-delay: -1130ms;
}

.drop:nth-child(10) {
  animation-delay: -1300ms;
}


@keyframes drop {
  50% {
    height: 45px;
    opacity: 0;
  }

  51% {
    opacity: 0;
  }

  100% {
    height: 1px;
    opacity: 0;
  }
}

.text {
  font-family: Helvetica, 'Helvetica Neue', sans-serif;
  letter-spacing: 1px;
  text-align: center;
  margin-left: -43px;
  font-weight: bold;
  margin-top: 20px;
  font-size: 12px;
  color: #a0a0a0;
  width: 200px;
}

/* end of loader */



.img-logo {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #337cb4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #000;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3b3939;

}

ol li::marker {
  color: rgb(0, 0, 0) !important;

}

.dark ol li::marker {
  color: rgb(0, 0, 0) !important;

}

.alert-modal ol li::marker,
.dark.alert-modal ol li::marker {
  color: rgb(0, 0, 0) !important;

}

.dark.pre-request-text-list li {
  text-align: left;
  line-height: 2em;
  color: #337cb4;

}

.pre-request-text-list li {
  text-align: left;
  line-height: 2em;
  color: #000000;

}

.pre-request-text {
  position: relative;
  top: 20%;
  margin: 0 auto;
  height: fit-content;
  background-color: whitesmoke;
  width: 300px;
  padding: 30px 30px;
  text-align: center;
  border-radius: 15px;
  font-size: 18px;
  color: rgb(0, 0, 0) !important;

}

.dark.pre-request-text {

  background-color: rgb(35, 34, 34) !important;
  color: white !important;

}

span.pre-request-text-span {
  padding: 20px 0px 20px 12px;
  line-height: 1.8em;
}

.pre-request-text-example {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;

}

.weekly-carousel {
  margin-bottom: 70px;
}

.footer {
  margin-top: auto;
  /* background-color: #337cb4; */
  color: whitesmoke;
  text-align: center;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.footer-info-title {
  font-size: 20px;
  padding-bottom: 16px;
}

.footer-info-copy {
  font-size: 16px;

}

.contain {
  background-image: url("./img/app-bg.jpg");
  background-size: 100% 100%;
  /* height:120vh; */
  display: flex;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-direction: column;

}

.contain.dark {
  background-image: repeating-linear-gradient(45deg, rgba(142, 141, 141, 0.1) 0px, rgba(142, 141, 141, 0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(63, 63, 63), rgb(63, 63, 63));
  color: whitesmoke
}

.contain.dark .day-7-location-span {
  color: #337cb4;
}






.contain.dark .card-7-forecast .card-body {
  background-image: repeating-linear-gradient(45deg, rgba(97, 97, 97, 0.1) 0px, rgba(97, 97, 97, 0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(43, 43, 43), rgb(43, 43, 43)) !important;
}

.contain .card-7-forecast .card-body {
  background-image: linear-gradient(37deg, rgb(32, 218, 233), rgb(40, 21, 236)) !important;
}

.before-midday,
.after-midday {
  padding: 30px 24px 30px 24px;
}

.contain.dark .week-7-heading {
  padding: 30px 24px 30px 24px;

}


html,
body {
  width: 100% !important;

}

.weather-form {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: 0 auto;
  flex-grow: 2;
  height: 40px;
  padding-right: 10px;


}


.city-search-input {
  border-radius: 22px;
  flex-grow: 2;
  /* And hide the input's outline, so the form looks like the outline */
  border: none;
  padding: 10px;

}

.item-info-7 {
  height: 65px !important;
}

.city-search-input:focus {
  outline: none;
}

/* Add the focus effect to the form so it contains the button */
form:focus-within {
  outline: 1px solid #337cb4;
}

.app-heading {
  font-size: 50px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #fbbc04;
  padding: 10px 0px 10px 40px;
  flex-grow: 1;
}

.form-heading-container {
  display: flex;
  background-color: #337cb4;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

}

.temp-measure-select {
  display: flex;
  margin-left: 24px;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: 110px;
  height: 42px;
}

.temp-measure-select-button {
  flex: 1;
  padding: 10px;
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  text-align: center;
}

.temp-measure-select-button:first-child {
  flex: 1;
  padding: 10px;
  /* padding-right: 2px; */
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 30px !important;

}

.temp-measure-select-button:not(:last-child) {
  border-right: 1px solid #ccc;
}

.temp-measure-select-button:hover {
  background-color: #e6e6e6;
}



.get-weather-button,
.clear-input-button {
  padding: 10px;
  padding-right: 5px;
  margin: 0;
  outline: none;
  border: none;
}

.day-7-week-day {
  display: inline-block;
  padding: 0 10px;
  width: 55px;
}

.question-icon {
  padding-left: 5px;
  margin-top: 10px;

}

.rain-icon {
  display: none;
}

.save-icon {
  display: none;
}

.button-save-city:hover {
  color: #337cb4;
  transition: 0.15s all ease-in;
}

.pre-request-text-list li {
  text-align: left;
  line-height: 2em;
}



.air-polution-heading {
  color: #fbbc04;
}

.button-save-city {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: transparent;

  border-left-color: #5380a2;
  height: 40px;
  padding: 7px 14px 10px 14px;
  margin-left: -20px;
  margin-top: 0px;
  background-color: #eae6e6;
}

.favorite-modal .modal-dialog,
.popup {
  display: block !important;
  background: transparent !important;
  position: fixed !important;
  bottom: -40px !important;
  left: 0 !important;
  right: 0 !important;
  width: 96% !important;
  height: 100px !important;
}

.got-it-button {
  position: absolute;
  top: 10px;
  right: 16px;
  width: max-content;
}

.co2-polution-good {
  color: rgb(8, 167, 8);
}

.co2-polution-satisfactory {
  color: rgb(41, 245, 5);
}

.co2-polution-moderate {
  color: rgb(194, 203, 9);
}

.co2-polution-poor {
  color: rgb(224, 156, 7);
}

.co2-polution-unhealthy {
  color: rgb(198, 136, 3);
}

.co2-polution-very-unhealthy {
  color: rgb(220, 51, 51);
}

.co2-polution-hazard {
  color: rgb(220, 51, 51);
}

.air-uv-icons {
  margin: 0px 4px 0px 10px !important;
}

.co2-polution-extreme {
  color: rgb(107, 35, 144);
}

.barometer-icon {
  display: inline-block;
  animation: barUpDown 3s ease-in-out infinite;
}

@keyframes barUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }

  100% {
    transform: translateY(0);
  }
}

.air-polution-heading {
  font-size: 37px !important;

}

.air-polution-heading {
  font-size: 40px;
  text-align: left;
  padding: 10px 0 34px 90px;
}

.celcON {
  background-color: yellow;
  transition: all 0.25s ease-in;
}

.fahrON {
  background-color: yellow;
  transition: all 0.25s ease-in;
}

.dark .rain-icon {
  content: url("./components/weather/img/rain-dark.png");
}

.got-it-button:hover {
  background-color: #7caed4 !important;
}

.modal-header {
  display: none;
}

.default-city-note {
  padding-left: 10px;
}

.default-city {
  color: #063a61;
}

.moving-background-dark {
  background-image: url("./img/starry-night.jpg");
  /* background: linear-gradient(270deg, #626565, #090a0a); */
  background-size: 100% 100%;

  -webkit-animation: AnimationDark 120s ease infinite;
  -moz-animation: AnimationDark 120s ease infinite;
  animation: AnimationDark 120s ease infinite;
}

.moving-background-light {
  background-image: url("./img/sunny-day.jpg");

  /* background: linear-gradient(270deg, #b8c5c8, #5b91e6); */
  background-size: 100% 100%;

  -webkit-animation: AnimationLight 120s linear infinite;
  -moz-animation: AnimationLight 120s linear infinite;
  animation: AnimationLight 120s linear infinite;
}

@keyframes AnimationDark {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationLight {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

.weather-city {
  color: #fff;
  font-size: 30px;
  padding: 10px 20px;
  text-align: left;
  background-color: rgba(245, 244, 246, 0.2);
  padding: 16px 26px;
}

.card {

  height: 100% !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  text-align: left;


}

.weather-7-container {
  /* display: flex;
  flex-direction: row;
  align-items: stretch !important;
  overflow-x: scroll; */
}

div.week-container {
  /* display: flex;
  flex-direction: row;
  align-items: stretch !important;
  overflow-x: scroll; */
}

.card-7-forecast {

  /* height:100% !important; */
  border: 1px solid whitesmoke;
  /* width: 20%; */
  /* margin: 0px auto; */
  /* text-align: left; */
  /* display: flex;
  flex-direction: column;
  height: 100% !important; */


}

.slider-stack-v {
  display: none;
}

.carousel .temp-7-info-container {
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  top: 46px;
  /* right:50%; */
  margin: 0 auto;
  font-size: 40px;


}

.carousel .weather-city-7 {
  position: absolute;
  top: 0px;
  left: 0;
}

.carousel .sky-info-7 {
  position: absolute;
  top: 116px;
  left: 10%;
  font-size: 20px;
}

.carousel-item .card-image-7 {

  width: 80px !important;
  height: 80px !important;
}

.carousel .temperature-info-7 {
  font-size: 34px;

}

.carousel .chance-of-rain {
  position: absolute;
  top: 170px;
  left: 12%;
  font-size: 20px;
}

.carousel .chance-of-rain-data {
  position: absolute;
  top: 172px;
  left: 42%;
  font-size: 24px;
}

.carousel-item {
  height: 280px;
}

.week-4-days .chance-of-rain-data {
  position: absolute;
  top: 176px;
  left: 52%;
  font-size: 24px;
}

.week-3-days .chance-of-rain-data {
  position: absolute;
  top: 176px;
  left: 42%;
  font-size: 24px;
}

.week-3-days .temperature-info-7,
.week-4-days .temperature-info-7 {
  font-size: 32px;
}

.card-7-forecast .card-body {
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

}

.card-7-forecast .card-body:hover {
  opacity: 0.9;
  transition: all 0.2s ease-in;
}

.day-24-heading {
  font-size: 23px;
  padding: 30px 10px;
  text-align: center;
  line-height: 2em;
}

.week-7-heading {
  font-size: 23px;
  padding: 60px 10px;
  text-align: center;
  line-height: 2em;
}


.before-midday,
.after-midday {
  font-size: 20px;
  padding: 30px 24px;
  text-align: left;
}

.day-7-location-span {
  color: #0c436d;
}

.card-7-forecast .card-body.moving-background-dark {
  background-image: repeating-linear-gradient(45deg, rgba(97, 97, 97, 0.1) 0px, rgba(97, 97, 97, 0.1) 2px, transparent 2px, transparent 4px), linear-gradient(90deg, rgb(43, 43, 43), rgb(43, 43, 43));
}

.card-7-forecast .card-body.moving-background-light {
  background-image: linear-gradient(37deg, rgb(32, 218, 233), rgb(40, 21, 236));
}

.card-7-forecast .card-body {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 10px; */

}

.dark span.navbar-toggler-icon {
  background: url('./components/weather/img/navbar-toggler-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
}

.footer-links-container {
  display: flex;
  flex-direction: row;
}

.footer-links {
  display: inline;
  margin: 0 10px;

}

.weather-city-7 {
  /* width: 100% !important; */
  color: #fff;
  font-size: 16px;
  /* padding: 6px 20px; */
  text-align: left;
  background-color: rgba(245, 244, 246, 0.2);
  padding: 16px 20px !important;
  text-align: center;
  word-break: break-all !important;
  height: 40px !important;
}

.temperature-info {
  font-size: 80px;
  padding: 3px 20px;
}

.temperature-info-7 {
  padding: 3px 20px;
}

.card-image {
  height: 150px !important;
  width: 150px !important;
}

.week-container {
  /* justify-content: stretch; */

}

.app-heading {
  font-size: 0px !important;
}

.img-logo {
  display: inline-block;
  width: 90px;
  height: auto;
}

.get-weather-icon:hover {
  color: #fbbc04 !important;
  transition: all 0.15s ease-in;
}

.clear-input-icon:hover {
  color: #080000 !important;
  transition: all 0.15s ease-in;
}

.dark.clear-input-icon:hover {
  color: #337cb4 !important;
  transition: all 0.15s ease-in;
}


.hidden {
  display: none;
}

.visible {
  display: block;
}

.week-forecast .card-body {
  height: auto;
  padding-bottom: 20px;
}

img.card-image-7 {
  height: 60px !important;
  width: 60px !important;
}

/* .heavy-rain{
  height:43px !important;
  width: 50px !important;
} */

.broken-clouds {
  height: 150px;
  width: 180px;

}

.feels-like-info {
  padding: 5px 20px;
  text-align: left;
  padding: 10px 0 24px 90px;
}

.temp-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.week-forecast .temperature-info-7 {
  line-height: 1.4em;
}

.week-forecast .card-body {
  height: 100%;

}

.week-forecast {
  height: 100% !important;



}

.humidity-info,
.pressure-info,
.wind-speed-info,
.feels-like-info,
.uv-info,
.co-info,
.pm25-info,
.pm10-info,
.no2-info {
  font-size: 30px;
  width: fit-content !important;




}

.humidity-info {
  text-align: left;
  padding: 5px 20px;
  padding: 0px 0 24px 90px;

}

.pressure-info {
  text-align: left;
  padding: 5px 20px;
  padding: 0px 0 24px 90px;

}

.wind-speed-info {
  text-align: left;
  padding: 5px 20px;
  padding: 0px 0 25px 90px;

}

.wind-info {
  /* text-align: left; */
  /* padding: 5px 20px; */
  padding-left: 28px;

}

.uv-info {
  text-align: left;
  padding: 5px 20px;
  padding: 0px 0 100px 90px;

}

.co-info {
  padding: 0px 0 40px 90px;
  width: fit-content !important;

}

.pm25-info {
  padding: 0px 0 26px 90px;
  width: fit-content !important;

}

.pm10-info {
  padding: 0px 0 15px 90px !important;
  width: fit-content !important;
  text-align: left;
  line-height: 1.8em;

}

.no2-info {
  padding: 0px 0 40px 90px;
  width: fit-content !important;

}

.air-info-container {
  background-color: rgba(54, 55, 55, 0.5) !important;
  padding: 76px 10px 0px 10px;

}

div.weather-air-uv-container {
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 76px 10px 0px 10px;
}

.air-info-container>.item-title:first-child {
  padding-bottom: 0 !important;

}

.warning-alert {
  width: 50%;
  font-size: 20px;
  margin: 0px 0 26px 90px;
  line-height: 1.8em;

}

.chance-of-rain {
  color: whitesmoke;
  margin-top: 10px;
  line-height: 1.8em;



}

.chance-of-rain-data {
  color: whitesmoke;
  line-height: 1.8em;

}

.sky-info {
  font-size: 40px;
  text-transform: capitalize;
  text-align: left;
  padding: 10px 0 14px 90px;
}

.sky-info-7 {
  text-transform: capitalize;
  padding: 18px 10px 10px 10px !important;
  word-break: break-all !important;
}

.time-date-data {
  color: #e3dfdf;
  word-wrap: break-word;
}

@media screen and (max-width:1200px) {
  .toggle_btn {
    /* position:absolute;
    top:134px;
    right:128px; */
  }

  .weather-forecast-options {
    margin-left: 40px;
  }

  .form-heading-container {
    width: 92.5%;
    margin-left: 46px;
    margin-right: 4px;
  }

  .temp-measure-select {
    margin-left: 14px;
  }
}


@media screen and (min-width:1031px) and (max-width:1446px) {
  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 80px;
    height: auto;
  }

  .week-4-days .chance-of-rain-data {
    position: absolute;
    top: 210px;
    left: 13%;
    font-size: 24px;
  }

  .week-3-days .chance-of-rain-data {
    position: absolute;
    top: 210px;
    left: 13%;
    font-size: 24px;
  }

  .carousel-item .card-image-7 {

    width: 60px !important;
    height: 60px !important;
  }

  .carousel .chance-of-rain-data {
    position: absolute;
    top: 210px;
    left: 12%;
    font-size: 24px;
  }


}

@media screen and (min-width: 1199px) {
  #basic-navbar-nav.collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
}

@media screen and (max-width: 1198px) {
  #basic-navbar-nav.collapse {
    margin-bottom: 8px;

  }
}

@media screen and (min-width:700px) and (max-width:1030px) {

  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  .carousel .rain-icon,
  .card-7-forecast .rain-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 220px;
    left: 34%;
  }

  .carousel-item .card-image-7 {

    width: 45px !important;
    height: 45px !important;
  }

  .gap-3,
  .gap-4 {
    gap: 0rem !important;
  }

  .carousel .chance-of-rain {
    display: none;
  }

  .carousel .sky-info-7,
  .week-3-days .sky-info-7,
  .week-4-days .sky-info-7 {
    margin: 14px auto !important;
    /* position: static !important; */
    font-size: 18px;

  }

  .week-4-days .temperature-info-7 {
    font-size: 30px;
  }

  .carousel .chance-of-rain-data {
    position: absolute;
    top: 214px;
    left: 50%;
    font-size: 24px;
  }
}

@media screen and (max-width:782px) {
  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }



  .save-city-span {
    display: none;
  }

  .save-icon {
    display: inline;
  }

  .save-icon:hover {
    color: #337cb4;
    transition: 0.15s all ease-in;
  }

}


@media screen and (max-width:699px) {
  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  .carousel {
    display: block;
  }

  .carousel .card {
    height: 49px !important;
    display: block;



  }

  .weather-city-7 {
    padding: 16px 6px !important;
  }

  .chance-of-rain,
  .sky-info-7 {
    display: none !important;
  }

  .gap-4 {
    gap: 0rem !important;
  }

  .gap-3 {
    gap: 0rem !important;

  }

  div.hstack {
    display: flex !important;
    flex: 1 1 auto !important;
    flex-direction: column !important;
    align-self: stretch !important;
  }

  .carousel .temp-7-info-container {
    position: absolute;
    top: 2px !important;
    left: 56% !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    padding-left: 10px;
  }

  .carousel-item .card-image-7 {

    width: 40px !important;
    height: 40px !important;

  }

  .temperature-info-7 {
    font-size: 20px !important;
    margin-bottom: 0;
    padding-left: 10px;

  }

  .hstack {
    display: block !important;
  }


  .carousel .chance-of-rain-data {
    position: absolute;
    top: 4px;
    left: 38%;
    font-size: 20px;
  }

  .carousel .rain-icon,
  .card-7-forecast .rain-icon {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: 12px;
    left: 31%;
  }

  .day-24-heading {
    padding: 12px 10px 0 10px !important;
    margin-bottom: 0;

  }

  .before-midday,
  .after-midday {
    padding: 56px 24px 0px 24px;
  }

  .week-7-heading {

    padding: 16px 10px 0px 10px;
    margin-bottom: 0;
  }

  .weekly-carousel {
    margin-bottom: 18px;
  }
}

@media screen and (min-width:981px) and (max-width:1179px) {
  .app-heading {
    font-size: 36px;
  }

}

@media screen and (min-width:768px) and (max-width:979px) {
  .form-heading-container {
    padding: 12px 0px 12px 0px;
    width: 91%;

  }
}

@media screen and (max-width:980px) {
  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  .weather-form {
    display: flex;
    flex-direction: row;
    border: 1px solid #000;
    width: 100%;
    margin: 0 auto;
  }

  .sky-info-7 {
    font-size: 23px;
  }

  .card-7-forecast.week-forecast.item#card:last-child {
    /* display:block !important;
    width:100% !important; */
  }

  .card {
    width: 100%;
  }

  .item-title {
    width: 100%;
  }



  .weather-form {
    width: 50% !important;
  }

  .app-heading {
    width: 50% !important;
    font-size: 34px;

  }

  .weather-form {
    border: none;
  }



  .footer {
    margin-top: auto;


  }

  .weather-7-container {
    flex-wrap: wrap;
  }

  .week-container {
    /* flex-wrap: wrap; */
  }

  .card-7-forecast {

    /* height:100% !important; */

    /* width: 33.10% !important; */
    /* margin: 0px 0 20px 0; */
    /* text-align: left; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: stretch !important;  */
    /* height:208px !important;  */


  }



  .weather-city-7 {
    font-size: 16px;

  }
}


@media screen and (min-width:601px) and (max-width:767px) {
  .form-heading-container {
    width: 92% !important;
    margin-left: 28px !important;
  }

  .weather-forecast-options {
    margin-left: 22px !important;

  }
}


@media screen and (max-width:700px) {
  .footer {
    height: 170px !important;
    margin-top: auto;



  }


  .slider-stack-h {
    display: none;
  }

  .slider-stack-v {
    display: initial;
  }

  .weather-7-container {
    flex-wrap: wrap;
  }

  .contain.dark .before-midday,
  .contain.dark .after-midday {
    padding: 52px 24px 0px 24px !important;
  }

  .contain.dark .week-7-heading {
    padding: 46px 24px 0px 24px !important;

  }

  .card-7-forecast {

    /* height:100% !important; */

    /* width: 49.5% !important; */
    /* margin: 0px auto; */
    /* text-align: left; */
    /* display: flex; */
    /* flex-direction: column; */
    height: 100%;


  }

  .card-7-forecast:last-child {

    /* height:100% !important; */

    /* width: 100% !important; */
    /* margin: 0px auto; */
    /* text-align: left; */
    /* display: flex; */
    /* flex-direction: column; */
    height: 100%;


  }

  .weather-city-7 {
    font-size: 16px;

  }

  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

}

@media screen and (max-width:478px) {
  .app-heading {
    text-align: center;
    font-size: 26px !important;
  }

  .CookieConsent {
    font-size: 14px;
  }

  .CookieConsent div {
    flex: 1 0 90%;
  }

  div.footer {
    margin-top: auto;
    display: flex;
    align-items: start;
    height: 300px !important;

  }

  .footer-links-container {
    align-items: start;
    line-height: 1.9em;
    margin-left: 16px;
  }

  .footer-info-title {
    margin: 0 auto;
    margin-top: 10px;

  }

  .footer-info-copy {
    margin: 0 auto;
    margin-bottom: 20px;

  }

  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  p.pm25-info,
  .pm10-info {
    font-size: 24px;
    padding-left: 20px !important;
    width: fit-content !important;
  }
}


@media screen and (max-width:600px) {
  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  .weather-forecast-options {
    /* display: flex; */
    position: relative;
    top: -17px;
    left: 0 !important;
    margin-left: 0 !important;
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    /* overflow: hidden; */
    /* width: 80px; */
    /* height: 42px; */
  }

  .weather-form {
    border: none;
    padding: 0px 8px;
    padding-left: 0;
    padding-right: 0px !important;
    width: 100% !important;
    margin-left: 0px;
    margin-right: 0px;

  }

  h4.air-polution-heading {
    padding-left: 24px !important;
  }

  .moving-background-light {
    background-image: url("./img/sunny-day_cr.jpg") !important;
    background-size: 200% 100% !important;
    width: 100% !important;


  }

  .moving-background-dark {
    background-image: url("./img/starry-night_cr.jpg") !important;
    background-size: 200% 100% !important;
    width: 100% !important;


  }

  .temp-measure-select {
    display: flex;
    position: absolute;
    top: 184px;
    right: 8% !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 80px;
    height: 42px;
  }

  .temp-measure-select-button {
    flex: 1;
    padding: 10px;
    background-color: #f9f9f9;
    border: none;
    cursor: pointer;
    text-align: center;
  }

  .justify-content-end.navbar-collapse.collapse.show {
    height: 130px !important;
  }

  .get-weather-button {
    padding-right: 4;
  }

  .pm25-info {
    padding-left: 50px !important;
    width: fit-content !important;
  }


  .clear-input-button {
    padding-right: 2;
  }

  .form-heading-container {
    flex-direction: row;
    padding: 4px 0px 18px 0px;
    width: 96%;
    margin-left: 4px;
  }

  h1.app-heading {
    font-size: 32px;
    padding-bottom: 0px;
    padding-left: 0;
    text-align: center;

  }

  .weather-form {
    width: 90% !important;
    padding-right: none;

  }

  .temp-info-container {
    flex-direction: column;
  }

  .temperature-info {
    font-size: 60px;
    padding: 30px 0 4px 0;

  }

  .humidity-info,
  .pressure-info,
  .wind-speed-info,
  .feels-like-info,
  .sky-info,
  .uv-info,
  .co-info,
  .pm25-info,
  .pm10-info,
  .no2-info {
    font-size: 24px;
    padding-left: 20px !important;
    width: fit-content !important;



  }

  .preloader {
    position: relative;
    margin-left: -55px;
    margin-top: -100px;
    height: 110px;
    width: 110px;
    left: 50%;
    top: 280px;
  }

  .wind-speed-info {
    padding-bottom: 25px;
  }

  .sky-info {
    font-size: 34px;
    padding-bottom: 28px;
    /* padding-top:10px; */

  }

  .footer {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    margin-top: auto;
    height: 170px !important;
  }

  .weather-7-container {
    flex-wrap: wrap;
  }

  .card-7-forecast {

    /* height:100% !important; */

    /* width: 100% !important; */
    /* margin: 0px auto; */
    /* text-align: left; */
    /* display: flex; */
    /* flex-direction: column; */
    height: 100%;


  }

  /* .weather-city-7{
  font-size: 16px;
  word-wrap: break-word;
} */

}

@media screen and (max-width:400px) {
  .form-heading-container {
    flex-direction: column;
    padding: 4px 0px 18px 0px;
    width: 98%;
    margin-left: 4px;
  }

  .weather-forecast-option {
    /* font-size: 18px; */
    padding-right: 5px !important;
    padding-left: 5px !important;
    /* margin-top: 14px; */
    /* text-decoration: none; */
  
  }

  div.toggle-location-container {
    background-color: #938f8f !important;
    height: 110px !important;
    width: 35px !important;
    display: block;
    position: fixed;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end;
    justify-content: space-evenly;
    right: 14px !important;
    top: 300px !important;
    z-index: 3;
    border-radius: 16px;
    opacity: 0.8;
  }

  div.toggle-location-container:hover {
    opacity: 1;
    transition: all 0.2s ease-in;
  }

  .temp-measure-select {
    display: flex;
    position: absolute;
    top: 144px;
    left: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 80px;
    height: 42px;
  }

  .toggle_btn.pl-3:not(.location) {
    display: initial !important;
    position: static !important;
    margin: 0 auto;
    z-index: 2;
  }

  button.toggle_btn.location {
    display: initial !important;
    position: static !important;
    margin: 0 auto;
    z-index: 2;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* overflow: hidden; */
    /* width: 80px; */
    /* height: 42px; */
  }

  .justify-content-end.navbar-collapse.collapse.show {
    height: 130px !important;
  }

  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }
}

@media screen and (max-width:476px) {
  .default-city-note {
    padding-right: 58px;
  }

  .modal-body {
    padding-right: 80px;
  }

  .preloader {
    position: relative;
    margin-left: -55px;
    margin-top: -100px;
    height: 110px;
    width: 110px;
    left: 50%;
    top: 380px;
  }
}

@media screen and (min-width:288px) and (max-width:400px) {
  .form-heading-container {
    flex-direction: column;
    padding: 4px 0px 18px 0px;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .clear-input-button {
    padding-left: 13px;
  }

  .temp-measure-select {
    display: flex;
    position: absolute;
    top: 180px !important;
    left: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 80px;
    height: 42px;
  }

  .justify-content-end.navbar-collapse.collapse.show {
    height: 130px !important;
  }

  .weather-form {
    margin-right: 0;
  }

  .modal-content {
    bottom: 45% !important;
  }

  .weather-form {
    padding-right: 0px !important;
    width: 100% !important;
  }

  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  button.toggle_btn {
    top: 186px !important;
    position: absolute;
    left: 74%;
  }


}

@media screen and (max-width:478px) {
  .pre-request-text {
    width: 80%;
    height: fit-content;
  }

  .app-heading {
    font-size: 0px !important;
  }

  .footer-links-container {
    display: flex;
    flex-direction: column;
    /* text-align: left; */

  }

  .footer-links {
    padding-top: 10px;
  }

  .footer {
    height: 270px !important;

  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  .form-heading-container {
    width: 92% !important;
    margin: 0 10px 0 10px !important;
  }

  .temp-measure-select {
    margin-left: 22px;
    position: absolute;
    left: 0px !important;
    top: 190px !important;
  }

  .weather-forecast-options {
    position: relative;
    top: -11px !important;
    left: 97px !important;
  }

  .toogle_btn {
    margin-left: 14px;
  }

  .city-search-input {
    width: 84% !important;
  }

  .button-save-city {
    padding-left: 10px;
    padding-right: 10px;
  }

  p.pm25-info,
  .pm10-info,
  .no2-info,
  .c02-info {
    font-size: 24px;
    padding-left: 20px !important;
    width: fit-content !important;
    text-align: left;
  }
}

@media screen and (max-width:287.5px) {
  .form-heading-container {
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .clear-input-button {
    position: relative;
    right: 70px;
    background: none;
  }

  .fa-x {

    color: #000 !important;
  }

  .app-heading {
    font-size: 0px !important;
  }

  .img-logo {
    display: inline-block;
    width: 70px;
    height: auto;
  }

  .temp-measure-select {
    display: flex;
    position: absolute;
    top: 136px !important;
    left: -10px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 80px;
    height: 42px;
  }
  .weather-forecast-options {
    /* display: flex; */
    position: relative;
    top: -18px !important;
    left: -10px !important;
  }
  div.modal-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    padding-right: 0 !important;





  }

  .default-city-note {
    padding: 70px 70px !important;
    font-size: 16px;
    text-align: center;
  }

  .got-it-button {
    position: fixed;
    top: 15%;
    right: 80%;
  }

  .city-search-input {
    width: 90% !important;
  }

  .weather-form {
    width: 100% !important;
  }

  .clear-input-button.dark {
    background-color: #fff !important;
    border-left: 1px solid #000;
    padding-left: 7px !important;
  }

  button.toggle_btn {
    top: 176px !important;
    position: absolute;
    right: 42px;
  }

  button.location.toggle_btn {
    top: 264px !important;
    position: absolute;
    left: 48px !important;
  }

  p.pm25-info,
  .pm10-info,
  .no2-info,
  .co-info {
    font-size: 24px;
    padding-left: 20px !important;
    width: fit-content !important;
    text-align: left !important;
  }
}

@media screen and (max-width:287.5px) {

  .city-search-input {
    /* height: 40px; */
    width: 100% !important;
  }

  .form-heading-container.dark, .weather-form.dark, .form-heading-container, .weather-form{
    background-color: transparent !important;
  }

  .button-save-city {
    position: relative;
    top: 0;
    border-radius: 4px;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    right: 28px !important;
    border-left: 1px solid #000;
    color: #0e426e;
  }

  .temp-measure-select {
    position: absolute;
    top: 210px;
    border-radius: 4px;
    left: 31px;
    height: 41px;
  }

  .temp-measure-select-button {
    padding: 10px 10px;
    width: 34px !important;
    text-align: center !important;
    width: 100% !important;
  }

  .temp-measure-select-button:last-child {
    padding: 10px 10px !important;
    width: 60% !important;
    text-align: center !important;
  }

  .justify-content-end.navbar-collapse.collapse.show {
    height: 190px !important;
  }

  button.toggle_btn {
    /* position: absolute; */
    /* top: 265px !important; */
    /* left: 6px; */
    /* margin-left: 0 !important; */
    /* width: 24px !important; */
    display: initial !important;
        position: static !important;
        margin: 0 auto;
  }

  .clear-input-button {
    right: 38px !important;
    position: relative;
        /* right: 70px; */
        border-bottom-right-radius: 22px;
        border-top-right-radius: 22px;
        background: white;
  }

  p.pm25-info,
  .pm10-info,
  .no2-info,
  .co-info {
    font-size: 24px;
    padding-left: 20px !important;
    width: fit-content !important;
    text-align: left !important;
  }

  .pre-request-text {
    font-size: 16px;
    padding-bottom: 4px !important;
    word-break: break-all;
    width: 100%;
  }

  .footer-info-title {
    font-size: 17px !important;
  }

  .footer-links-container {
    font-size: 15px;

  }

  .footer-info-copy {
    font-size: 14px;

  }

  .temp-measure-select {
    border-bottom-left-radius: 0px 0px !important;
    border-top-left-radius: 0px 0px !important;
    height: 40.5px;

  }

  .button.save-city {
    height: 40.8px;
  }

  button.clear-input-button{
    right: 32px !important;
  }
  div.toggle-location-container {
    background-color: #938f8f !important;
    height: 110px !important;
    width: 35px !important;
    display: block;
    position: fixed;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end;
    justify-content: space-evenly;
    right: 14px !important;
    top: 300px !important;
    z-index: 3;
    border-radius: 16px;
    opacity: 0.8;
}
div.toggle-location-container {
  opacity: 1;
  transition: all 0.2s ease-in;

}
.weather-forecast-option {
  /* font-size: 18px; */
  padding: 4px 14px 4px 10px !important;
  /* margin-top: 14px; */
  /* text-decoration: none; */

}
}